var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-form-cambio-tepvehiculo"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Cambiar Vehículo")]),_c('button',{ref:"closeModal1",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Vehículo Nuevo")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Placa","label":"placa","options":_vm.listasForms.vehiculos,"filterable":false},on:{"search":_vm.buscarVehiculos,"input":function($event){return _vm.validarDocumentos()}},model:{value:(_vm.form.vehiculo),callback:function ($$v) {_vm.$set(_vm.form, "vehiculo", $$v)},expression:"form.vehiculo"}})],1),_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Justificación")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.justificacion),expression:"form.justificacion"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.form.justificacion)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "justificacion", $event.target.value)}}})])])])]),_c('div',{staticClass:"modal-footer justify-content-between"},[(
              _vm.$store.getters.can('tep.turnos.cambiarConductor') &&
                !_vm.$v.form.$invalid
            )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }