<template>
  <div class="row">
    <div class="modal fade" id="modal-form-cambio-tepvehiculo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambiar Vehículo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-md-12">
                <label>Vehículo Nuevo</label>
                <v-select
                  :class="[
                    $v.form.vehiculo.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.vehiculo"
                  placeholder="Placa"
                  label="placa"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.vehiculos"
                  :filterable="false"
                  @search="buscarVehiculos"
                  @input="validarDocumentos()"
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label>Justificación</label>
                <div>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="form.justificacion"
                    :class="
                      $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="
                $store.getters.can('tep.turnos.cambiarConductor') &&
                  !$v.form.$invalid
              "
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "TepTurnoCambiarVehiculo",
  components: {
    vSelect,
  },
  data() {
    return {
      dataTurno: null,
      turno_id: null,
      conductor_id: null,
      form: {
        turno_id: null,
        estado: null,
        vehiculo: null,
        justificacion: null,
      },
      listasForms: {
        vehiculos: [],
      },
    };
  },
  validations() {
    return {
      form: {
        justificacion: {
          required,
        },
        vehiculo: {
          required,
        },
      },
    };
  },
  methods: {
    llenar_modal(item) {
      this.dataTurno = item;
      this.resetForm();

      this.form.vehiculo_ant = {
        id: this.dataTurno.vehiculo_id,
        placa: this.dataTurno.vehiculo.placa,
      };
    },

    resetForm() {
      this.form = {
        turno_id: this.dataTurno.id,
        postulacion: this.dataTurno.postulacion,
        estado: {
          numeracion: this.dataTurno.estado,
          descripcion: this.dataTurno.nEstado,
        },
        vehiculo: null,
        justificacion: null,
      };
    },

    buscarVehiculos(search, loading) {
      this.listasForms.vehiculos = [];
      if (search != "") {
        let me = this;
        loading(true);
        var url = "/api/tep/turno/listaVehiculos?search=" + search;
        axios
          .get(url, {
            params: {
              tipo_vehiculo_id: this.dataTurno.tipo_vehiculo_id,
            },
          })
          .then(function(response) {
            me.listasForms.vehiculos = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async validarDocumentos() {
      this.$parent.cargando = true;
      await axios
        .get("/api/tep/turno/validarDocumentos", {
          params: {
            id: this.form.vehiculo.id,
            entidad: "Vehiculo",
          },
        })
        .then((response) => {
          this.$parent.cargando = false;
          if (response.data.error) {
            this.form.vehiculo = null;
            this.$swal({
              icon: "error",
              title: response.data.error.msg,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        });
    },

    save() {
      this.$swal({
        title: "Esta seguro de cambiar este vehículo?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar el cambio!",
      }).then((result) => {
        if (result.value) {
          this.$parent.cargando = true;
          axios
            .put("/api/tep/turno/cambiarVehiculo", {
              data: this.form,
            })
            .then((response) => {
              this.$refs.closeModal1.click();
              this.$parent.getIndex();
              this.$parent.cargando = false;
              this.form = {};
              this.$swal({
                icon: "success",
                title: response.data.mensaje,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
